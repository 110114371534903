<template>
  <div>
    <v-dialog v-model="showingUserModal" width="700" scrollable persistent minHeight="1200">
      <v-form ref="userForm" autocomplete="new-password" @submit.prevent="createUser" v-if="!areaPage">
        <v-card :loading="loading">
          <v-card-title class="text-h5 grey lighten-2">
            {{ editFormId ? $t('Editar Usuario') : $t('Crear Usuario') }}
          </v-card-title>
          <v-card-text style="max-height: 500px">
            <v-row class="mt-2">
              <v-col cols="6" md="6">
                <!-- v-if="!editFormId" -->
                <v-text-field
                  ref="name"
                  v-model="user_form.data.name"
                  outlined
                  dense
                  autocomplete="off"
                  hide-details="auto"
                  :rules="[() => !!user_form.data.name || $t('Campo requerido')]"
                  :label="t('Nombre completo')"
                />
              </v-col>

              <v-col cols="6" md="6">
                <v-text-field
                  ref="username"
                  v-model="user_form.user.username"
                  outlined
                  dense
                  autocomplete="off"
                  :rules="[() => !!user_form.user.username || $t('Campo requerido')]"
                  hide-details="auto"
                  :label="t('Nombre de usuario')"
                />
              </v-col>

              <v-col cols="6" md="6" v-if="!editFormId">
                <v-text-field
                  ref="password"
                  v-model="user_form.user.password"
                  :append-icon="showPassword ? icons.mdiEye : icons.mdiEyeOff"
                  :rules="[() => !!user_form.user.password || $t('Campo requerido')]"
                  :type="!showPassword ? 'password' : 'text'"
                  @click:append="showPassword = !showPassword"
                  name="input-10-1"
                  outlined
                  dense
                  autocomplete="off"
                  hide-details="auto"
                  :label="t('Contraseña')"
                />
              </v-col>

              <v-col cols="6" md="6" v-if="!editFormId">
                <v-text-field
                  ref="confirm_password"
                  v-model="user_form.user.confirm_password"
                  :append-icon="showPassword ? icons.mdiEye : icons.mdiEyeOff"
                  :rules="[() => !!user_form.user.confirm_password || $t('Campo requerido')]"
                  :type="!showPassword ? 'password' : 'text'"
                  @click:append="showPassword = !showPassword"
                  name="input-10-1"
                  outlined
                  dense
                  autocomplete="off"
                  hide-details="auto"
                  :label="t('Confirmar contraseña')"
                />
              </v-col>

              <v-col cols="6" md="6">
                <v-text-field
                  ref="phone"
                  v-model="user_form.data.phone"
                  outlined
                  dense
                  autocomplete="off"
                  hide-details="auto"
                  :label="t('Teléfono')"
                />
              </v-col>

              <v-col cols="6" md="6">
                <v-text-field
                  ref="email"
                  v-model="user_form.user.email"
                  outlined
                  dense
                  autocomplete="off"
                  hide-details="auto"
                  :rules="[v => (user_form.user.email !== '' && rules.email(v)) || $t('Email inválido')]"
                  label="Email"
                />
              </v-col>

              <v-col cols="6" md="6">
                <v-autocomplete
                  ref="role"
                  v-model="user_form.data.role"
                  :items="filteredRoleList"
                  :rules="[() => typeof user_form.data.role === 'number' || $t('Campo requerido')]"
                  :label="t('Rol')"
                  outlined
                  dense
                  :placeholder="t('Selecciona...')"
                  required
                  :hint="editFormId ? `* ${$t('cambiar rol')}` : ''"
                  persistent-hint
                ></v-autocomplete>
              </v-col>

              <v-col cols="6" md="6" class="d-flex" v-if="user_form.data.role === 0">
                <v-checkbox v-model="user_form.data.is_superuser" dense> </v-checkbox>
                <span class="my-2">
                  {{ $t('Es super administrador') }}
                </span>
              </v-col>

              <v-col cols="6" md="6" class="d-flex" v-if="editFormId">
                <v-checkbox v-model="editPassword" dense> </v-checkbox>
                <span class="my-2">
                  {{ $t('¿Cambiar contraseña?') }}
                </span>
              </v-col>
            </v-row>

            <v-row class="mt-2" v-if="editPassword">
              <v-col cols="6" md="6">
                <v-text-field
                  ref="password"
                  v-model="user_form.user.password"
                  :append-icon="showPassword ? icons.mdiEye : icons.mdiEyeOff"
                  :rules="[() => !!user_form.user.password || $t('Campo requerido')]"
                  :type="!showPassword ? 'password' : 'text'"
                  @click:append="showPassword = !showPassword"
                  name="input-10-1"
                  outlined
                  dense
                  autocomplete="off"
                  hide-details="auto"
                  :label="t('Contraseña')"
                />
              </v-col>

              <v-col cols="6" md="6">
                <v-text-field
                  ref="confirm_password"
                  v-model="user_form.user.confirm_password"
                  :append-icon="showPassword ? icons.mdiEye : icons.mdiEyeOff"
                  :rules="[() => !!user_form.user.confirm_password || $t('Campo requerido')]"
                  :type="!showPassword ? 'password' : 'text'"
                  @click:append="showPassword = !showPassword"
                  name="input-10-1"
                  outlined
                  dense
                  autocomplete="off"
                  hide-details="auto"
                  :label="t('Confirmar contraseña')"
                />
              </v-col>
            </v-row>
          </v-card-text>

          <v-col class="pt-0" align="left" v-if="errorMessage" v-for="msg in errorMessage">
            <span class="caption" style="color: red">
              {{ msg }}
            </span>
          </v-col>

          <div v-if="editFormId">
            <small class="ms-3 text-caption">{{
              `* ${$t('cambiar el rol eliminaría relaciones con paises, regiones y escuelas.')}`
            }}</small>
          </div>
          <v-divider></v-divider>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="tertiary" outlined @click="close()" :disabled="loading">
              {{ t('Cancelar') }}
            </v-btn>
            <v-btn color="primary" type="submit" :disabled="loading">
              {{ t('Siguiente') }}
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-form>

      <v-card ref="userAreaForm" v-if="areaPage" :loading="loading">
        <v-card-title class="d-flex justify-space-between">
          <span>
            {{ $t('Agregar') }}
            {{
              userData && userData.role === 1
                ? `${$t('País')}`
                : userData && userData.role === 2
                ? `${$t('Region')}`
                : userData && userData.role === 3
                ? `${$t('Escuela')}`
                : ''
            }}
          </span>
          <v-btn
            v-if="getUserRole === 0 && userData.role !== 1"
            color="primary"
            outlined
            small
            class="ms-6"
            @click="creationModalHandling(userData.role)"
          >
            <v-icon>{{ icons.mdiPlusCircleOutline }}</v-icon>
            <span>{{ userData && userData.role === 2 ? t('Crear Region') : t('Crear Escuela') }}</span>
          </v-btn>
        </v-card-title>
        <v-card-text>
          <v-row class="mt-2">
            <v-col cols="12" v-if="userData && userData.role !== 1">
              <div class="text-l font-weight-medium">
                {{ $t('Filtrar') }}:
              </div>
              <v-row>
                <v-col cols="6">
                  <v-select
                    class="me-3"
                    :items="[{ id: -1, name: '-' }, ...countriesList]"
                    hide-details="true"
                    v-model="filters.country"
                    :label="$t('Paises')"
                    :item-text="item => countryParser[item.name]"
                    :item-value="item => item"
                    @input="getRegionList()"
                  >
                    <template v-slot:no-data>
                      <v-list-item>
                        <v-list-item-title>
                          {{ $t('Sin información disponible') }}
                        </v-list-item-title>
                      </v-list-item>
                    </template>
                  </v-select>
                </v-col>
                <v-col cols="6" class="text-right" v-if="userData && userData.role === 2">
                  <v-btn :disabled="!filters.country" color="primary" class="me-3 px-1 no-uppercase text-end" @click="addAllAreas()">
                    <v-icon>{{ icons.mdiPlusCircleOutline }}</v-icon>
                    <span>{{ $t('Asignar todas') }}</span>
                  </v-btn>
                </v-col>
                <v-col cols="6">
                  <v-select
                    v-if="userData && userData.role !== 2"
                    :items="regionList"
                    hide-details="true"
                    v-model="filters.region"
                    :label="$t('Regiones')"
                    :item-text="item => item.name"
                    :item-value="item => item"
                    @change="getSchoolList()"
                  >
                    <template v-slot:no-data>
                      <v-list-item>
                        <v-list-item-title>
                          {{ $t('Sin información disponible') }}
                        </v-list-item-title>
                      </v-list-item>
                    </template>
                  </v-select>
                </v-col>
              </v-row>
            </v-col>

            <v-col cols="8" class="">
              <div class="text-l font-weight-medium mb-3">
                {{$t('Asignar selección')}}:
              </div>
              <v-select
                :items="selectOptions"
                hide-details="true"
                v-model="area_form.object"
                :label="
                  userData && userData.role === 1
                    ? `${$t('Paises')}`
                    : userData && userData.role === 2
                    ? `${$t('Regiones')}`
                    : userData && userData.role === 3
                    ? `${$t('Escuelas')}`
                    : ''
                "
                item-text="name"
                item-value="id"
                outlined
              >
                <template v-slot:no-data>
                  <v-list-item>
                    <v-list-item-title>
                      {{$t('Sin información disponible')}}
                    </v-list-item-title>
                  </v-list-item>
                </template>
              </v-select>
            </v-col>
            <v-col cols="4" class="d-flex justify-center align-center pt-3 pb-0">
              <v-btn color="primary" class="me-3 px-1 no-uppercase" @click="addArea()">
                <v-icon>{{ icons.mdiPlusCircleOutline }}</v-icon>
                <span>{{ $t('Asignar') }}</span>
              </v-btn>
            </v-col>
          </v-row>
          <v-row class="justify-center">
            <v-col cols="6" v-if="userData && userData.user_areas.length" class="d-flex justify-center">
              <v-card-subtitle class="pb-0 mt-3">{{
                userData && userData.role === 1
                  ? `${$t('Paises asignados')}`
                  : userData && userData.role === 2
                  ? `${$t('Regiones asignadas')}`
                  : userData && userData.role === 3
                  ? `${$t('Escuelas asignadas')}`
                  : ''
              }}</v-card-subtitle>
            </v-col>
            <v-col cols="6" v-if="!userData.user_areas.length" class="d-flex justify-center">
              <span v-if="userData && userData.role === 1">{{ $t('No hay paises asignados para este usuario.') }}</span>
              <span v-if="userData && userData.role === 2">{{
                $t('No hay regiones asignadas para este usuario.')
              }}</span>
              <span v-if="userData && userData.role === 3">{{
                $t('No hay escuelas asignadas para este usuario.')
              }}</span>
            </v-col>
            <v-col cols="10" class="d-flex flex-wrap grey-border-top" v-if="userData && userData.user_areas.length">
              <v-chip v-for="area in userData.user_areas" class="ma-1">
                <span v-if="userData && userData.role === 1">{{ area.area_name }}</span>
                <span v-else>{{ area.area_name }}</span>
                <v-btn @click="removeArea(area.id)" x-small icon color="error"
                  ><v-icon>{{ icons.mdiClose }}</v-icon></v-btn
                >
              </v-chip>
            </v-col>
          </v-row>
        </v-card-text>

        <v-col class="pt-0" align="left" v-if="errorMessage" v-for="msg in errorMessage">
          <span class="caption" style="color: red">
            {{ msg }}
          </span>
        </v-col>

        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" @click="finish()" :disabled="loading">
            {{ $t('Finalizar') }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <RegionFormModal ref="region_form_modal" @reload-region-list="getSelectOptions()" />

    <SchoolFormModal ref="school_form_modal" @reload-school-list="getSelectOptions()" />
  </div>
</template>

<script>
import { ref } from '@vue/composition-api'
import { useUtils } from '@core/libs/i18n'
import RegionFormModal from '@/components/Regions/RegionFormModal'
import SchoolFormModal from '@/components/Schools/SchoolFormModal'

import { mdiClockTimeFourOutline, mdiClose, mdiEye, mdiEyeOff, mdiPlusCircleOutline } from '@mdi/js'

export default {
  data: () => {
    return {
      loading: false,
      user_form: {
        user: {
          username: '',
          password: '',
          confirm_password: '',
          email: '',
          want_email: true,
        },
        data: {
          name: '',
          role: null,
          phone: '',
          area: [],
          is_superuser: false
        },
      },
      area_form: {
        user_information: null,
        object: null,
      },
      editFormId: null,
      editPassword: false,
      showPassword: false,
      errorMessage: null,
      localError: null,
      countriesList: [],
      regionList: [],
      schoolList: [],
      filters: {
        country: null,
        region: {},
      },
      areaPage: false,
      selectOptions: [],
      userData: null,
      areaIds: [],
      allOptions: [],
      rules: {
        email: value => {
          const pattern =
            /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
          return pattern.test(value) || false
        },
      },
    }
  },
  mounted() {
    this.getCountryList()
    this.getRegionList()
  },
  methods: {
    handleRoleSelection(selectedRole) {
      // console.warn(selectedRole);
    },
    openModal(id_to_edit) {
      id_to_edit ? this.setItemToEdit(id_to_edit) : (this.showingUserModal = true)
    },
    async setItemToEdit(id_to_edit) {
      const item = await this.$api.getUser(id_to_edit)
      if (item) {
        this.editFormId = id_to_edit
        this.user_form.data.user = item.data.user_id ?? null
        this.user_form.user.username = item.data.username ?? null
        this.user_form.user.email = item.data.email ?? null
        this.user_form.data.name = item.data.name ?? null
        this.user_form.data.role = item.data.role ?? null
        this.user_form.data.is_superuser = item.data.is_superuser ?? false
        this.user_form.data.phone = item.data.phone ?? null
        this.showingUserModal = true
      }
    },
    async createUser() {
      if (this.$refs.userForm.validate()) {
        this.loading = true
        const form = this.parseForm()

        try {
          const response = this.editFormId
            ? await this.$api.updateUser(this.editFormId, form)
            : await this.$api.createUser(form)

          this.$emit('reload-user-list')

          if (response.role !== 0) {
            this.toAreaPage(response)
            this.resetForm()
          } else {
            this.close()
          }
        } catch (err) {
          console.log(err.response)

          let errorMessages = []

          if (err?.response?.data?.password) {
            errorMessages.push(`${this.$t('Contraseña')}: ` + this.$t(`${err.response.data.password}`))
          }

          if (err?.response?.data?.confirm_password) {
            errorMessages.push(
              `${this.$t('Confirmar contraseña')}: ` + this.$t(`${err.response.data.confirm_password}`),
            )
          }

          if (err?.response?.data?.username) {
            errorMessages.push(`${this.$t('Nombre de usuario')}: ` + this.$t(`${err.response.data.username}`))
          }

          if (err?.response?.data?.non_field_errors) {
            errorMessages.push(`${this.$t('Contraseña')}: ` + this.$t(`${err.response.data.non_field_errors}`))
          }

          if (err?.response?.data?.email) {
            errorMessages.push(`${this.$t('Email')}: ` + this.$t(`${err.response.data.email}`))
          }

          if (err?.response?.data?.detail) {
            errorMessages.push(this.$t(`${err.response.data.detail}`))
          }

          if (errorMessages.length > 0) {
            this.errorMessage = errorMessages
          } else {
            this.errorMessage = [`${this.$t('Ha ocurrido un error, intente nuevamente más tarde')}`]
          }
        } finally {
          this.loading = false
        }
      }
    },
    async addArea() {
      if (this.errorMessage) this.errorMessage = []

      try {
        if (!this.areaIds.includes(this.area_form.object)) {
          const response = await this.$api.addArea(this.area_form)
          this.getSelectOptions()
        }
        const user = await this.$api.getUser(this.area_form.user_information)
        this.userData = user.data
        this.areaIds = user.data.user_areas.map(el => el.object_id)
        this.getSelectOptions()
      } catch (e) {
        if (e.response.data) {
          if (e.response.status >= 404) {
            this.errorMessage = ['Error']
          } else {
            this.errorMessage = [`${this.$t(e.response.data)}`]
          }
        }
      }
    },
    async addAllAreas() {
      for (const el of this.selectOptions)  {
        console.log(el.id);
        const form = {
          object: el.id,
          user_information: this.area_form.user_information
        }
        try {
          const response = await this.$api.addArea(form)
        } catch (error) {
          console.log(error);
        }
      }
      try {
        const user = await this.$api.getUser(this.area_form.user_information)
        this.userData = user.data
        this.areaIds = user.data.user_areas.map(el => el.object_id)
        console.log('sound good');
        this.getSelectOptions()
      } catch (error) {
        console.log(error);
      }
    },
    async removeArea(area) {
      if (this.getUserRole === 0 || (this.areaIds.length > 1 && this.getUserRole !== 0)) {
        try {
          const response = await this.$api.removeArea(area)
          const user = await this.$api.getUser(this.area_form.user_information)
          this.userData = user.data
          // console.warn(this.userData)
          this.areaIds = this.userData.user_areas.map(el => el.object_id)
          this.getSelectOptions()
          this.$forceUpdate()
        } catch (e) {
          console.log(e)
        }
      } else {
        this.errorMessage = [`${this.$t('Debe asignar al menos un elemento')}`]
      }
    },
    async toAreaPage(createdUserData) {
      try {
        this.userData = createdUserData
        this.areaIds = createdUserData.user_areas.map(el => el.object_id)
        this.area_form.user_information = createdUserData.user_information
          ? createdUserData.user_information
          : createdUserData.id
        this.getSelectOptions()
        this.areaPage = true
      } catch (e) {
        console.log(e)
      }
    },
    close() {
      this.resetForm()
      this.showingUserModal = false
    },
    resetForm() {
      this.$refs.userForm.reset()
      this.editFormId = null
      this.errorMessage = null
      this.localError = null
      this.user_form.user.want_email = true
      this.user_form.user.email = ''
    },
    finish() {
      if (this.getUserRole === 0 || (this.areaIds.length > 0 && this.getUserRole !== 0)) {
        this.areaPage = false
        this.areaIds = []
        this.selectOptions = []
        this.filters.country = null
        this.filters.region = {}
        this.area_form.user_information = null
        this.area_form.object = null
        this.userData = null
        this.allOptions = []
        this.errorMessage = null
        this.showingUserModal = false
        this.$emit('reload-user-list')
      } else {
        this.errorMessage = [`${this.$t('Debe asignar al menos un elemento')}`]
      }
    },
    parseForm() {
      let editForm = {
        user: {
          username: this.user_form.user.username,
          email: this.user_form.user.email,
        },
        name: this.user_form.data.name,
        role: this.user_form.data.role,
        is_superuser: this.user_form.data.is_superuser,
        phone: this.user_form.data.phone,
        change_password: false,
      }
      if (this.editPassword) {
        editForm.change_password = true
        editForm.password = this.user_form.user.password
        editForm.confirm_password = this.user_form.user.confirm_password
      }

      const formParsed = JSON.parse(JSON.stringify(this.editFormId ? editForm : this.user_form))
      return formParsed
    },
    async getCountryList() {
      if (!this.countriesList.length) {
        const query = {
          paginated: false,
        }
        try {
          const response = await this.$api.getCountryList(query)
          this.countriesList = response
          return response
        } catch (error) {
          console.log(error)
        }
      }
    },
    async getRegionList() {
      this.filters.region = {}
      this.area_form.object = null

      const query = {
        paginated: false,
        country: this.filters.country?.name ?? null,
      }

      try {
        const response = await this.$api.getRegionList(query)
        this.regionList = response.length? [{ id: -1, name: '-' }, ...response] : []
        if (this.userData?.role === 2) {
          this.filterUnselectedAreas(response)
        }
        if (this.userData?.role === 3) {
          this.getSchoolList()
        }
        return response
      } catch (error) {
        console.log(error)
      }
    },
    async getSchoolList() {
      this.area_form.object = null
      try {
        const query = {
          paginated: false,
          region: this.filters.region?.name ?? null,
        }
        const response = await this.$api.getSchoolList(query)
        this.schoolList = response
        this.filterUnselectedAreas(response)
        return response
      } catch (error) {
        console.log(error)
      }
    },
    async getSelectOptions() {
      try {
        const response =
          this.userData && this.userData.role === 1
            ? this.countriesList
            : this.userData && this.userData.role === 2
            ? await this.getRegionList()
            : await this.getSchoolList()

        this.allOptions = response
        this.filterUnselectedAreas(response)
      } catch (e) {
        console.log(e)
      }
    },
    filterUnselectedAreas(response) {
      this.selectOptions = this.areaIds.length ? response.filter(el => !this.areaIds.includes(el.id)) : response
      if (this.userData && this.userData.role === 1) {
        this.selectOptions = this.selectOptions.map(item => ({ id: item.id, name: this.countryParser[item.name] }))
      }
    },
    creationModalHandling(userRole) {
      userRole === 2 ? this.$refs.region_form_modal.openModal() : this.$refs.school_form_modal.openModal()
    },
  },
  setup() {
    const showingUserModal = ref(false)
    const { t } = useUtils()

    return {
      showingUserModal,
      t,
      icons: {
        mdiClose,
        mdiClockTimeFourOutline,
        mdiEye,
        mdiEyeOff,
        mdiPlusCircleOutline,
      },
    }
  },
  computed: {
    getUserRole() {
      return this.$store.getters['session/getUserRole']()
    },
    roleList() {
      return [
        { text: this.$t('Administrador'), value: 0 },
        { text: this.$t('Representante de país'), value: 1 },
        { text: this.$t('Coordinador regional'), value: 2 },
        { text: this.$t('Representante escolar'), value: 3 },
      ]
    },
    filteredRoleList() {
      const userRole = this.getUserRole

      if (userRole === 0) {
        return this.roleList
      } else if (userRole === 1) {
        return this.roleList.slice(2)
      } else if (userRole === 2) {
        return [this.roleList[this.roleList.length - 1]]
      } else {
        return []
      }
    },
    countryParser() {
      const parser = {
        '-': '-',
        AR: 'Argentina',
        BO: 'Bolivia',
        BR: this.$t('Brasil'),
        CL: 'Chile',
        CO: 'Colombia',
        CR: 'Costa Rica',
        CU: 'Cuba',
        DO: this.$t('República Dominicana'),
        EC: 'Ecuador',
        GD: this.$t('Granada'),
        GF: this.$t('Guayana Francesa'),
        GT: 'Guatemala',
        GY: this.$t('Guyana'),
        HN: 'Honduras',
        HT: 'Haiti',
        JM: 'Jamaica',
        MX: 'Mexico',
        NI: 'Nicaragua',
        PA: 'Panama',
        PE: 'Perú',
        PR: 'Puerto Rico',
        PY: 'Paraguay',
        SR: 'Surinam',
        SV: 'El Salvador',
        UY: 'Uruguay',
        VE: 'Venezuela',
        IN: 'India'
      }
      return parser
    },
  },
  components: { SchoolFormModal, RegionFormModal },
}
</script>

<style scoped>
.no-uppercase {
  text-transform: unset !important;
}

.grey-border-top {
  border-top: solid;
  border-width: 1px;
  border-color: rgba(94, 86, 105, 0.14);
}
</style>

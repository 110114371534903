<template>
  <div>
    <v-card class="mb-6">
      <v-card-title>{{t('Usuarios')}}</v-card-title>
      <UserList ></UserList>
    </v-card>

  </div>
</template>

<script>

import UserList from '@/components/Users/UserList.vue';
import { useUtils } from '@core/libs/i18n'


export default {
 components: {
    UserList,
},
 setup() {
  const { t } = useUtils()
    return {
      t,
      // icons
      icons: {
      },
    }
  },

}
</script>
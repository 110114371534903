<template>
  <div>
    <v-dialog v-model="showModal" width="auto" minHeight="1200">
      <v-card>
        <v-card-title class="text-h5 text-center grey lighten-2">
          <v-icon large class="me-2" color="primary">
            {{ icons.mdiAccountCircleOutline }}
          </v-icon>
          {{ userData.username }}
        </v-card-title>

        <v-divider></v-divider>

        <v-card-text>
          <strong class="me-2">{{ $t("Nombre") }}:</strong>
          {{ userData.name ? userData.name : "-" }}
        </v-card-text>

        <v-card-text>
          <strong class="me-2">{{ $t("Rol") }}:</strong>
          <span v-if="userData.role">{{
            `${roleParser[userData.role]}` | translate
          }}</span>
          <span v-else> - </span>
        </v-card-text>

        <v-card-text>
          <strong class="me-2"> Email:</strong>
          {{ userData.email ? userData.email : "-" }}
        </v-card-text>

        <v-card-text>
          <strong class="me-2">{{ $t("Teléfono") }}:</strong>
          {{ userData.phone ? userData.phone : "-" }}
        </v-card-text>

        <v-card-text>
          <strong class="me-2" v-if="userData && userData.user_areas">
            {{ areaLabel + ':'}}
          </strong>
          <span v-if="userData && userData.user_areas && userData.user_areas.length === 0">-</span>
          <span v-else v-for="(area, idx) in userData.user_areas" :key="area.id">
            {{ `${area.area_type === "country" ? countryParser[area.area_name] : area.area_name}${idx + 1 < userData.user_areas.length ? "," : ""}` }}
          </span>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { mdiAccountCircleOutline } from "@mdi/js";

export default {
  data() {
    return {
      showModal: false,
      loading: true,
      userData: {},
    };
  },
  props: {
    roleParser: {
      type: Object,
      default: {},
    },
  },
  computed: {
    areaLabel() {
      const hasMultipleAreas = this.userData.user_areas.length > 1;
      let label = "";
      switch (this.userData.role) {
        case 1:
          label = this.$t(`${hasMultipleAreas ? "Paises" : "País"}`);
          break;
        case 2:
          label = this.$t(`${hasMultipleAreas ? "Regiones" : "Region"}`);
          break;
        case 3:
          label = this.$t(`${hasMultipleAreas ? "Escuelas" : "Escuela"}`);
          break;
        default:
          label = "";
      }
      return label;
    },
    countryParser() {
      const parser = {
        AR: "Argentina",
        BO: "Bolivia",
        BR: "Brazil",
        CL: "Chile",
        CO: "Colombia",
        CR: "Costa Rica",
        CU: "Cuba",
        DO: this.$t("República Dominicana"),
        EC: "Ecuador",
        GD: this.$t("Granada"),
        GF: this.$t("Guayana Francesa"),
        GT: "Guatemala",
        GY: "Guyana",
        HN: "Honduras",
        HT: "Haiti",
        JM: "Jamaica",
        MX: "Mexico",
        NI: "Nicaragua",
        PA: "Panama",
        PE: "Perú",
        PR: "Puerto Rico",
        PY: "Paraguay",
        SR: "Surinam",
        SV: "El Salvador",
        UY: "Uruguay",
        VE: "Venezuela",
        IN: "India"
      };
      return parser;
    },
  },
  methods: {
    async openDetailModal(id) {
      this.loading = true;
      try {
        const user = await this.$api.getUser(id);

        this.loading = false;
        this.showModal = true;
        this.userData = user.data;
      } catch (e) {
        console.log(e);
      }
    },

    close() {
      this.showModal = false;
      this.loading = true;
    },
  },
  setup() {
    return {
      icons: {
        mdiAccountCircleOutline,
      },
    };
  },
};
</script>

<style lang="scss" scoped></style>

import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VIcon } from 'vuetify/lib/components/VIcon';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c(VDialog,{attrs:{"width":"auto","minHeight":"1200"},model:{value:(_vm.showModal),callback:function ($$v) {_vm.showModal=$$v},expression:"showModal"}},[_c(VCard,[_c(VCardTitle,{staticClass:"text-h5 text-center grey lighten-2"},[_c(VIcon,{staticClass:"me-2",attrs:{"large":"","color":"primary"}},[_vm._v(" "+_vm._s(_vm.icons.mdiAccountCircleOutline)+" ")]),_vm._v(" "+_vm._s(_vm.userData.username)+" ")],1),_c(VDivider),_c(VCardText,[_c('strong',{staticClass:"me-2"},[_vm._v(_vm._s(_vm.$t("Nombre"))+":")]),_vm._v(" "+_vm._s(_vm.userData.name ? _vm.userData.name : "-")+" ")]),_c(VCardText,[_c('strong',{staticClass:"me-2"},[_vm._v(_vm._s(_vm.$t("Rol"))+":")]),(_vm.userData.role)?_c('span',[_vm._v(_vm._s(_vm._f("translate")(("" + (_vm.roleParser[_vm.userData.role])))))]):_c('span',[_vm._v(" - ")])]),_c(VCardText,[_c('strong',{staticClass:"me-2"},[_vm._v(" Email:")]),_vm._v(" "+_vm._s(_vm.userData.email ? _vm.userData.email : "-")+" ")]),_c(VCardText,[_c('strong',{staticClass:"me-2"},[_vm._v(_vm._s(_vm.$t("Teléfono"))+":")]),_vm._v(" "+_vm._s(_vm.userData.phone ? _vm.userData.phone : "-")+" ")]),_c(VCardText,[(_vm.userData && _vm.userData.user_areas)?_c('strong',{staticClass:"me-2"},[_vm._v(" "+_vm._s(_vm.areaLabel + ':')+" ")]):_vm._e(),(_vm.userData && _vm.userData.user_areas && _vm.userData.user_areas.length === 0)?_c('span',[_vm._v("-")]):_vm._l((_vm.userData.user_areas),function(area,idx){return _c('span',{key:area.id},[_vm._v(" "+_vm._s(("" + (area.area_type === "country" ? _vm.countryParser[area.area_name] : area.area_name) + (idx + 1 < _vm.userData.user_areas.length ? "," : "")))+" ")])})],2)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }